const actions = {
  EDIT: "edit",
  CREATE: "create",
};

const transactionTypes = {
  CHARGE: "charge",
  CASHBACK: "cashback",
};

const providers = {
  DIRECT: "direct",
  FACEBOOK: "facebook",
  GOOGLE: "google",
  APPLE: "apple",
};

const map = {
  LAT: process.env.VUE_APP_GOOGLE_MAPS_FALLABACK_LAT,
  LNG: process.env.VUE_APP_GOOGLE_MAPS_FALLABACK_LNG,
};

const modelTypes = {
  STORE_BRAND: "store_brand",
  STORE: "store",
  TRANSACTION_CB: "transaction_cashback",
  TRANSACTION_CH: "transaction_charge",
};

const importable = {
  STORE_BRANDS: process.env.VUE_APP_BRAND_IMPORT_EXAMPLE_URL,
  STORES: process.env.VUE_APP_STORE_IMPORT_EXAMPLE_URL,
  CONFIRMED_BILLS: process.env.VUE_APP_BILLS_IMPORT_EXAMPLE_URL,
};

export default {
  actions,
  transactionTypes,
  providers,
  map,
  modelTypes,
  importable,
};
