/*eslint no-unused-vars: "off"*/

export default {
  objectToFormData(input) {
    const z = function (obj, form, namespace) {
      var fd = form || new FormData();

      for (var property in obj) {
        let fd = form || new FormData();
        let formKey;

        for (let property in obj) {
          if (
            Object.prototype.hasOwnProperty.call(obj, property) &&
            obj[property] != undefined
          ) {
            formKey = !namespace ? property : namespace + "[" + property + "]";
            // if the property is an object, but not a File, use recursivity.
            if (obj[property] instanceof Date) {
              fd.append(formKey, obj[property].toISOString());
            } else if (
              typeof obj[property] === "object" &&
              !(obj[property] instanceof File)
            ) {
              z(obj[property], fd, formKey);
            } else {
              // if it's a string or a File object
              fd.append(formKey, obj[property]);
            }
          }
        }

        return fd;
      }

      return fd;
    };

    return z(input);
  },

  // Get nested property value.
  getProperty(propertyName, object) {
    var parts = propertyName.split("."),
      length = parts.length,
      i,
      property = object || this;

    for (i = 0; i < length; i++) {
      property = property[parts[i]] || [];
    }

    return property;
  },
};
