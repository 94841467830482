/*eslint no-unused-vars: "off"*/

export default {
  moneyFormat(value) {
    var formatter = new Intl.NumberFormat("hr-HR", {
      style: "currency",
      currency: "HRK",
    });

    return formatter.format(value);
  },
};
