<template>
  <div>
    <div class="d-flex flex-column flex-root">
      <div class="page d-flex flex-row flex-column-fluid">
        <SideMenu />

        <div class="wrapper d-flex flex-column flex-row-fluid">
          <Header />

          <div class="content d-flex flex-column flex-column-fluid">
            <div class="container-xxl">
              <main>
                <!-- Page content injection: -->
                <slot />
              </main>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>
