import moment from "moment";

export default {
  getDate(date) {
    return moment(date).format("MMM Do YYYY");
  },

  getDateSecondary(date) {
    return moment(date).format("D. MMM, YYYY");
  },

  getISODate(date) {
    return moment(date).format("YYYY-MM-DD");
  },

  defaultGraphRange(days) {
    const endDate = new Date();
    const startDate = new Date(
      new Date().setDate(endDate.getDate() - (days || 7))
    );
    return [startDate, endDate];
  },
};
