import Swal from "sweetalert2";

const error = (props) => {
  return new Promise((resolve) => {
    Swal.fire({
      showCancelButton: true,
      reverseButtons: true,
      title: props.title || "",
      text: props.description || "",
      icon: "error",
      cancelButtonText: props.cancel || "Cancel",
      confirmButtonText: props.submit || "Submit",
    }).then((result) => {
      if (result.isConfirmed) {
        resolve("confirmed");
      }
    });
  });
};

const info = (props) => {
  return new Promise((resolve) => {
    Swal.fire({
      showCancelButton: true,
      reverseButtons: true,
      title: props.title || "",
      text: props.description || "",
      icon: "info",
      cancelButtonText: props.cancel || "Cancel",
      confirmButtonText: props.submit || "Submit",
    }).then((result) => {
      if (result.isConfirmed) {
        resolve("confirmed");
      }
    });
  });
};

export default {
  error,
  info,
};
