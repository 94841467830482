<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">
      <div
        class="d-flex flex-column flex-lg-row-auto auth-bg w-xl-600px positon-xl-relative align-items-center justify-content-center"
      >
        <div
          class="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y"
        >
          <div
            class="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20 align-items-center justify-content-center"
          >
            <router-link to="/" class="py-9 pt-lg-20">
              <img
                :alt="$appName"
                src="@/assets/img/logo-light.svg"
                class="h-40px"
              />
            </router-link>

            <h1 class="fw-bolder text-white fs-2qx pb-5 pb-md-10">
              For {{ this.$schemeName }}
            </h1>
          </div>
        </div>
      </div>

      <!-- Page content injection: -->
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.classList.add("auth-bg");
  },

  unmounted() {
    document.body.classList.remove("auth-bg");
  },
};
</script>

<style lang="scss" scoped>
.auth-bg {
  background-image: url("~@/assets/img/auth-bg.png");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  overflow: hidden;

  @media only screen and (min-width: 992px) {
    min-height: 100vh;
  }
}
</style>
