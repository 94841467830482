// Check if url is image file.
const isImageFromUrl = (url) =>
  url.match(/\.(jpeg|jpg|gif|png)$/) != null || false;

// Check if name string is image file.
const isImageType = (type) => type.split("/")[0] === "image" || false;

export default {
  isImageFromUrl,
  isImageType,
};
