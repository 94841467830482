export default {
  validationErrors(e) {
    const serverErrors = e.data.errors;
    let errors = [];

    for (const property in serverErrors) {
      errors.push({
        name: property,
        value: serverErrors[property],
      });
    }

    return errors;
  },
};
